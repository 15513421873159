import React from 'react'

const GwInitials = ({ className = '' }) => {
    return (
        <svg id="initials-svg" className={className} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.13 60.09">
            <path d="M18.71,46.11A152.5,152.5,0,0,0,26,27.47a62.51,62.51,0,0,0,2-9.58c.37-2.85.95-6.46-.09-9.23-1.21-3.23-5.2-4.62-7.77-2a10.48,10.48,0,0,0-2.74,7.57,6.54,6.54,0,0,0,4.87,6.12,11,11,0,0,0,8.24-1.81A14.92,14.92,0,0,0,37.17,3c-.63-2.76-5-2.14-4.92.66l.35,22.5.08,5.2c0,1.56.36,3.48,0,5-.25,1-1.78,1-2.64,1.15a20.49,20.49,0,0,1-5,.14c-2.74-.23-6.13-1-8.32-2.82-.61-.49-1.3-1.18-1-2a2.79,2.79,0,0,1,.35-.3A4.13,4.13,0,0,1,17.56,32a6.79,6.79,0,0,1,2.94.39A17.44,17.44,0,0,1,24,34.07c2.54,1.49,4.87,3.33,7.44,4.79a19.48,19.48,0,0,0,20.24-.23c2.7-1.72.2-6.05-2.52-4.31a14.48,14.48,0,0,1-9.46,2.24c-3.17-.36-5.91-2-8.51-3.76-4.84-3.22-11.89-8.37-17.8-4.47a6,6,0,0,0-1.29,8.89c2.27,2.89,6.37,4.33,9.87,5a23.57,23.57,0,0,0,11.38-.35,5.81,5.81,0,0,0,4-3.85c.56-1.8.33-3.85.3-5.71L37.25,3.63l-4.91.67a9.57,9.57,0,0,1-3,8.82A8.76,8.76,0,0,1,25,15.51a2.67,2.67,0,0,1-2-.23,1.45,1.45,0,0,1-.57-1,4.41,4.41,0,0,1,.25-2.06,5.54,5.54,0,0,1,.38-.95,9.09,9.09,0,0,0,.65-1c0-.05-.55,0-.54,0a3.53,3.53,0,0,0,.19.55,14.63,14.63,0,0,1,0,3.5A55.07,55.07,0,0,1,20.5,28.33a157.92,157.92,0,0,1-6.11,15.25,2.58,2.58,0,0,0,.9,3.42,2.51,2.51,0,0,0,3.42-.89Z" transform="translate(-10.67 8.52)"></path>
            <path d="M49.92,5.74A89,89,0,0,0,56.38,43.8a2.59,2.59,0,0,0,3.08,1.74c3.16-.64,3.72-4.08,4.09-6.73.47-3.34.83-6.69,1-10.06A116,116,0,0,0,64,8.68,2.54,2.54,0,0,0,62.41,6.4a2.45,2.45,0,0,0-2.73.52c-2.53,3.06-2.33,6.72-1.81,10.45q.85,6.18,2.3,12.26T63.56,41.4c1.17,3.44,2.18,7.81,5.51,9.82a2.53,2.53,0,0,0,3.67-1.5,69.5,69.5,0,0,0,2.89-13.24q1-6.88,2-13.76L81.76-5.37A2.59,2.59,0,0,0,80-8.45,2.53,2.53,0,0,0,76.94-6.7L72.83,21.39q-1,6.88-2,13.76A69.77,69.77,0,0,1,67.92,48.4l3.68-1.5a5.24,5.24,0,0,1-1.79-2.84q-1-2.54-1.8-5.11-1.67-5-2.91-10.19T63,18.37a36.67,36.67,0,0,1-.53-4.43,5.18,5.18,0,0,1,.72-3.49L59,8.68a115.68,115.68,0,0,1,.73,17.65c-.1,2.9-.32,5.8-.64,8.69-.16,1.38-.34,2.76-.56,4.14-.08.49-.29,1-.33,1.48,0,0-.14.27-.14.27,0-.16.85-.34.12-.19l3.08,1.75A85.38,85.38,0,0,1,54.92,5.74c.17-3.22-4.83-3.21-5,0Z" transform="translate(-10.67 8.52)"></path>
        </svg>
    )
}

export default GwInitials